import Spacer from "../base/Spacer";

import tiktokIcon from "../../assets/images/tiktok.svg";
import facebookIcon from "../../assets/images/facebook.png";
import instagramIcon from "../../assets/images/Instagram.png";
import whatsappIcon from "../../assets/images/WhatsApp.png";

import {
  FooterContainer,
  FooterWrapper,
  Logo,
  ServicesBox,
  ServicesContainer,
  ServicesTitle,
  ServicesText,
  SubscribeTitle,
  SubscriptionButton,
  EmailInput,
  Line,
  FooterBottom,
  SocialMediaIconsContainer,
  SocialMediaIcon,
  Link,
} from "./styles";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        {/* <Logo
          src={smartLogo}
          alt="logo"
          id="logo"
        /> */}
        <Spacer vertical space={40} />
        <ServicesContainer>
          <ServicesBox>
            <div>
              <ServicesTitle>Our Services</ServicesTitle>
              <Spacer vertical space={40} />
              <ServicesText>Buy Data</ServicesText>
              <Spacer vertical space={20} />
              <ServicesText> Airtime TopUp</ServicesText>
              <Spacer vertical space={20} />
              <ServicesText>Cable Subscription</ServicesText>
              <Spacer vertical space={20} />
              <ServicesText>Bill Payment</ServicesText>
            </div>
            <div>
              <ServicesTitle>Other Links</ServicesTitle>

              <Link href="/privacy">Privacy and Terms</Link>
            </div>
          </ServicesBox>
          <div className="subscribe-container">
            <SubscribeTitle>Subcribe</SubscribeTitle>
            <Spacer vertical space={10} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "#fff",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                borderRadius: "20px",
              }}
            >
              <EmailInput placeholder="Email Us" />
              <SubscriptionButton>Click to Subscribe</SubscriptionButton>
            </div>
            <Spacer vertical space={10} />
            <SubscribeTitle>Email Us</SubscribeTitle>
            <Spacer vertical space={10} />
            <ServicesText>Topifydigitalservices@gmail.com</ServicesText>
          </div>
        </ServicesContainer>
      </FooterWrapper>
      <Line></Line>
      <FooterBottom>
        <ServicesText>(C) Copyright 2023. All Rights Reserved</ServicesText>
        <SocialMediaIconsContainer>
          <a
            href="https://www.tiktok.com/@topify.ng"
            target="_blank"
            rel="noreferrer"
          >
            <SocialMediaIcon src={tiktokIcon} />{" "}
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61553231920468&mibextid=ZbWKwL"
            target="_blank"
            rel="noreferrer"
          >
            <SocialMediaIcon src={facebookIcon} />
          </a>
          <a
            href="https://instagram.com/topify.ng?igshid=MTk0NTkyODZkYg=="
            target="_blank"
            rel="noreferrer"
          >
            <SocialMediaIcon src={instagramIcon} />
          </a>
          <a href="#">
            <SocialMediaIcon src={whatsappIcon} />
          </a>
        </SocialMediaIconsContainer>
      </FooterBottom>
    </FooterContainer>
  );
};

export default Footer;

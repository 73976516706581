import Navbar from "./components/Navbar/Navbar";
import AboutUs from "./components/AboutUs/AboutUs";
import Footer from "./components/Footer/Footer";
import { DataProvider } from "./hooks/dataProvider";
import PrivacyContent from "./components/Privacy/Privacy";

function Privacy() {
  return (
    <div>
      <DataProvider>
        <Navbar />

        <PrivacyContent />

        <Footer />
      </DataProvider>
    </div>
  );
}

export default Privacy;

import { AboutUsWrapper } from "./styles";

const PrivacyContent = () => {
  return (
    <AboutUsWrapper id="about">
      <div>
        <div style={{ maxWidth: "70%" }} className="p-20 container max-w-2xl">
          <h1 className="text-6xl pt-2 font-semibold border-b mt-6  mb-2">
            Privacy Policy
          </h1>

          <p className="text-base  text-gray-700 mb-2">
            Welcome to Smartdatalinks. We are committed to protecting your
            privacy and ensuring the security of your personal information. This
            Privacy Policy outlines our practices regarding the collection, use,
            and protection of your personal data.
          </p>

          <h1
            style={{ textDecoration: "underline" }}
            className="font-semibold text-2xl underline my-5"
          >
            Information We Collect
          </h1>
          <p className="text-base  text-gray-700 mb-2">
            We may collect the following types of information from users of our
            website and services: - Personal Information: This may include your
            name, email address, phone number, and other information you provide
            during the registration or order process.
            <ul className="space-y-2 list-disc mt-2">
              <li>
                <b> Payment Information:</b> To process payments for your
                orders, we may collect and store payment card information,
                billing addresses, and related data. Please note that we use
                third-party payment processors to handle payments, and we do not
                store your payment card information on our servers.
              </li>

              <li>
                <b> Usage Data:</b> We collect information about your
                interactions with our website, such as the pages you visit, your
                IP address, and device information.
              </li>
            </ul>
          </p>

          <h1
            style={{ textDecoration: "underline" }}
            className="font-semibold text-2xl underline my-5"
          >
            How We Use Your Information
          </h1>
          <p className="text-base  text-gray-700 mb-2">
            We use your personal information for the following purposes:
            <ul className="space-y-2 list-disc mt-2">
              <li>
                To provide and manage the services you request, including data
                and airtime purchases, bill payments, and cable subscriptions.
              </li>

              <li>To process and complete your transactions.</li>
              <li>
                To communicate with you regarding your orders, account updates,
                and service-related notifications.
              </li>

              <li>
                To improve and enhance our services, user experience, and
                customer support.
              </li>
            </ul>
          </p>

          <h1
            style={{ textDecoration: "underline" }}
            className="font-semibold text-2xl underline my-5"
          >
            Data Security
          </h1>
          <p className="text-base  text-gray-700 mb-2">
            We take data security seriously and implement measures to protect
            your personal information. However, no data transmission over the
            internet can be guaranteed as entirely secure. While we do our best
            to protect your data, we cannot guarantee the security of any
            information you transmit to us.
          </p>

          <h1
            style={{ textDecoration: "underline" }}
            className="font-semibold text-2xl underline my-5"
          >
            Sharing of Information
          </h1>
          <p className="text-base  text-gray-700 mb-2">
            We may share your personal information with third parties in the
            following situations:
            <ul className="space-y-2 list-disc mt-2">
              <li>
                With service providers and partners who assist us in providing
                and improving our services.
              </li>

              <li>To process and complete your transactions.</li>
              <li>
                With law enforcement, government authorities, or other third
                parties when required by law or to protect our legal rights.
              </li>
            </ul>
          </p>

          <h1
            style={{ textDecoration: "underline" }}
            className="font-semibold text-2xl underline my-5"
          >
            Your Rights
          </h1>
          <p className="text-base  text-gray-700 mb-2">
            You have certain rights concerning your personal information,
            including the right to access, correct, or delete your data. To
            exercise these rights or if you have questions about your data,
            please contact us at help@smartdatalinks.com.
          </p>

          <h1 className="font-semibold text-2xl underline my-5">
            Cookies and Tracking
          </h1>
          <p className="text-base  text-gray-700 mb-2">
            We may use cookies and similar tracking technologies to enhance your
            browsing experience and collect usage data. You can manage your
            cookie preferences through your browser settings
          </p>

          <h1
            style={{ textDecoration: "underline" }}
            className="font-semibold text-2xl underline my-5"
          >
            Changes to this Policy
          </h1>
          <p className="text-base  text-gray-700 mb-2">
            We may update this Privacy Policy as necessary to reflect changes in
            our practices or legal requirements. Please review this policy
            periodically to stay informed.
          </p>
          <h1 className="font-semibold text-2xl underline my-5">Contact Us</h1>
          <p className="text-base  text-gray-700 mb-2">
            If you have any questions or concerns about this Privacy Policy,
            please contact us. at help@smartdatalinks.com.
          </p>

          <br />
          <br />
          <h1 className="text-4xl pt-2 font-semibold border-b mt-6  mb-2">
            Terms and Conditions
          </h1>

          <h1
            style={{ textDecoration: "underline" }}
            className="font-semibold text-2xl underline my-5"
          >
            Acceptance of Terms
          </h1>

          <p className="text-base  text-gray-700 mb-2">
            Welcome to Smartdatalinks, These Terms and Conditions govern your
            use of our website and services. By using our website and services,
            you agree to comply with and be bound by these terms. If you do not
            agree to these terms, please do not use our website and services.
          </p>

          <h1
            style={{ textDecoration: "underline" }}
            className="font-semibold text-2xl underline my-5"
          >
            Service Description
          </h1>

          <p className="text-base  text-gray-700 mb-2">
            We provide data and airtime sales, bill payment, and cable
            subscription services in Nigeria. Users can make transactions and
            manage their accounts through our website.
          </p>

          <h1
            style={{ textDecoration: "underline" }}
            className="font-semibold text-2xl underline my-5"
          >
            User Registration
          </h1>

          <p className="text-base  text-gray-700 mb-2">
            To access certain features and make transactions on our website, you
            may be required to register an account. You are responsible for
            maintaining the confidentiality of your account information and
            agree to notify us immediately of any unauthorized access.
          </p>

          <h1
            style={{ textDecoration: "underline" }}
            className="font-semibold text-2xl underline my-5"
          >
            Orders and Payments
          </h1>

          <p className="text-base  text-gray-700 mb-2">
            <ul className="space-y-2 list-disc mt-2">
              <li>
                You agree to provide accurate and complete information when
                making transactions on our website.
              </li>

              <li>
                We use third-party payment processors to handle payments. We do
                not store payment card information on our servers.
              </li>
              <li>
                We reserve the right to cancel or reject orders at our
                discretion.
              </li>
            </ul>
          </p>

          <h1
            style={{ textDecoration: "underline" }}
            className="font-semibold text-2xl underline my-5"
          >
            User Conduct
          </h1>

          <p className="text-base  text-gray-700 mb-2">
            <ul className="space-y-2 list-disc mt-2">
              <li>
                You agree not to use our website for any illegal or unauthorized
                purpose.
              </li>

              <li>
                You must not interfere with the proper functioning of our
                website or use it in a way that could harm other users.
              </li>
            </ul>
          </p>

          <h1
            style={{ textDecoration: "underline" }}
            className="font-semibold text-2xl underline my-5"
          >
            Privacy and Data Security
          </h1>

          <p className="text-base  text-gray-700 mb-2">
            Our Privacy Policy outlines our practices regarding the collection,
            use, and protection of your personal information. By using our
            services, you consent to our collection and use of your information.
          </p>

          <h1
            style={{ textDecoration: "underline" }}
            className="font-semibold text-2xl underline my-5"
          >
            Intellectual Property
          </h1>

          <p className="text-base  text-gray-700 mb-2">
            All content, logos, and trademarks on our website are the property
            of [Your Company Name] and are protected by intellectual property
            laws. You may not use our content without our written permission.
          </p>

          <h1
            style={{ textDecoration: "underline" }}
            className="font-semibold text-2xl underline my-5"
          >
            Limitation of Liability
          </h1>

          <p className="text-base  text-gray-700 mb-2">
            We make every effort to ensure the accuracy and security of our
            website, but we do not guarantee it will be error-free or
            uninterrupted. We are not liable for any direct, indirect, or
            consequential damages arising from the use of our website.
          </p>

          <h1
            style={{ textDecoration: "underline" }}
            className="font-semibold text-2xl underline my-5"
          >
            Changes to Terms and Conditions
          </h1>

          <p className="text-base  text-gray-700 mb-2">
            We may update these Terms and Conditions as necessary. It is your
            responsibility to review these terms periodically. Your continued
            use of our website after changes will constitute your acceptance of
            the revised terms.
          </p>

          <h1
            style={{ textDecoration: "underline" }}
            className="font-semibold text-2xl underline my-5"
          >
            Termination
          </h1>

          <p className="text-base  text-gray-700 mb-2">
            We reserve the right to terminate your access to our website and
            services for any reason, including violations of these terms.
          </p>

          <h1
            style={{ textDecoration: "underline" }}
            className="font-semibold text-2xl underline my-5"
          >
            Contact Us
          </h1>

          <p className="text-base  text-gray-700 mb-2">
            If you have any questions or concerns about these Terms and
            Conditions, please contact us.
          </p>
        </div>
      </div>
    </AboutUsWrapper>
  );
};

export default PrivacyContent;

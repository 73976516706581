import styled from "styled-components";

export const NavBarRoot = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 3;
`;

export const NavBarContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  width: 80vw;
  margin: auto;
`;

export const Image = styled.img`
  height: auto;
  width: 100%;
`;

export const ImageContainer = styled.div`
  width: 78px;
  height: 67.014px;

  @media screen and (max-width: 600px) {
    height: auto;
    width: 80px;
  }
`;

export const LogoText = styled.p`
  color: #09417a;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
`;

export const LinksRow = styled.div`
  /* width: 361px; */
  height: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  top: 0;
  position: sticky;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const Link = styled.a`
  text-align: center;
  font-size: 0.875rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin: 0 15px;
  color: #001e40;
  border-radius: 5px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    border-bottom: 1px solid #001e40;
  }
`;

export const LinkColumn = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1000;
  width: calc(100% - 53%);
  max-width: 209px;
  text-align: right;
  padding: 20px 10px;
  transition: transform 0.5s ease-in;
  gap: 40px;
  height: 400px;
  background: #fff;
  display: ${({ active }) => (active ? "flex" : "none")};
  flex-direction: column;
  align-items: flex-start;
  right: 0;

  .close-button {
    align-self: flex-end;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`;
